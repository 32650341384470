/**
 * Created by brunekninja on 12/09/16.
 */
(function($) {
    // Navigaton bar scroll event
    $('.nav-toggle').click(function () {
        // default vars
        var time = 250;
        var dataTarget = $(this).data('target');
        var expanded = $(this).attr('aria-expanded');
        var width = $( window ).width();

        function nav_closed() {
            $(dataTarget).removeClass('in');
            $(dataTarget).find('ul#menu-primary').removeClass('menu-open');
            $('.nav-toggle').attr('aria-expanded', 'false').prop('disabled', false);
        }

        function nav_opened() {
            $('.nav-toggle').attr('aria-expanded', 'true').prop('disabled', false);
            $(dataTarget).find('ul#menu-primary').addClass('menu-open');
        }

        if(expanded === 'true'){
            // animate
            $(dataTarget).animate({
                left: width + 'px'
            }, time, function() {
                nav_closed();
            });
        }
        else{
            // add disabled to button
            $('.nav-toggle').prop('disabled', true);
            // add class in to targeted div
            $(dataTarget).addClass('in').animate({
                left: '0'
            }, time, function() {
                nav_opened();
            });
        }
    });


    function autoheight(a) {
        if (!$(a).prop('scrollTop')) {
            do {
                var b = $(a).prop('scrollHeight');
                var h = $(a).height();
                $(a).height(h - 15);
            }
            while (b && (b !== $(a).prop('scrollHeight')));
        }
        $(a).height($(a).prop('scrollHeight') - 10);
    }

    //auto resize comment textarea
    $('textarea#comment').keyup(function (e) {
        autoheight(this);
    });
    autoheight($('textarea#comment'));

    //init faux masonry effects
    var grid = $('#grid').length;
    if (grid){
        new AnimOnScroll(
          document.getElementById( 'grid' ),
            {
            minDuration : 0.3,
            maxDuration : 0.9,
            viewportFactor : 0.3
            }
        );
    }



})(jQuery);
